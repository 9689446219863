/*
Shipping page
Display text about shipping
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import { homeUrl } from '../../../functions/pageUrls';
import { Link } from 'react-router-dom'

var pageTitle = "Dostava";
class Shipping extends React.Component{

    componentDidMount() {
        }
        
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
             </Helmet>
                <section id="p_shipping" className="section static-page shipping-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <h4>Vrste i cijene dostave</h4>
                                <ul>
                                    <li>Dostava je besplatna širom BiH za narudžbe veće od 100 KM.</li>
                                    <li>Dostava za narudžbe od 40 – 100 KM se plaća dodatnih 5 KM.</li>
                                    <li>Cijena dostave za područja izvan BiH se utvrđuje prema specifičnim tarifama za svaku zemlju. Sve narudžbe za 
                                        inostranstvo se obavljaju isključivo putem email-a narudzba@zdravo.ba.</li>
                                    <li>Ukoliko želite izbjeći troškove dostave pošiljku možete preuzeti i sa odabranog GRATIS PICK-UP mjesta već u 
                                        roku od 24 h nakon izvršene narudžbe. <b>Bit ćete obavješteni telefonskim putem o prispjeću narudžbe na PICK-UP mjesto</b>. 
                                        Iznos minimalne narudžbe za GRATIS PICK-UP: 25 KM</li>
                                </ul><br />
                                <p>PICK-UP mjesta se nalaze na sljedećim lokacijama:</p>
                                <ul>
                                    <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                                    <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                                    <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                                </ul>
                            <h4>Koliko dugo traje isporuka naručenih artikala?</h4>
                            <p>Narudžbe izvržene prije podne se šalju isti ili najkasnije naredni dan (osim nedelje). Firme kojima predajemo proizvode ih u 
                                pravilu dostavljaju u roku od 1-2 radna dana na Vašu kućnu adresu.</p>
                             <br />
                             <h4>Ko snosi odgovornost u slučaju kašnjenja isporuke?</h4>
                             <p>Dostavu vršimo putem kompanija koje su specijalizovane za dostave pošiljaka u BiH. U poslovanju ovih kompanija propisani su uslovi i rokovi isporuke 
                                 pošiljaka na predviđene lokacije, te u slučaju kašnjenja u isporuci servis dostave snosi određene konsekvence propisane njihovim pravilima poslovanja. </p>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default Shipping