/*
Single product page
Display single product page with data about selected product
*/
import React from 'react';
import Slider from "react-slick";
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import formatMoney from '../../../functions/formatMoney'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ProductPopup from './ProductPopup'
import Product from './Product'
import ReactHtmlParser from 'react-html-parser';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Header from '../Header'
import Footer from '../Footer'
import { timeoutDelay } from '../../../functions/config'
import StarRatings from 'react-star-ratings';
import { cartUrl, shippingUrl, categoryUrl, homeUrl } from '../../../functions/pageUrls';
import LoaderBox from '../../helpers/LoaderBox';
import {calculatePrice} from '../../../functions/calculatePrice'
import SingleProductSlider from './SingleProductSlider';
import axios from 'axios';
import { addRecension, addCart } from '../../helpers/UserFunctions'
import { withGlobalState } from 'react-globally'
import Popup from '../homepage/Popup'
import placanje_pouzecem_img from '../../../static/placanje_pouzecem_img.png'

var randomString = require('random-string');
var sliderArr;
var cartId = randomString({length: 60});
class SingleProduct extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          singleProduct: [],
          gallerySwiper: null,
          sliderImage1: '',
          sliderImage2: '',
          sliderImage3: '',
          sliderImage4: '',
          productQuantity: 1,
          showPopupClass: '',
          popupTitle: '',
          popupPrice: '',
          popupOldPrice: '',
          popupImage: '',
          popupSlug: '',
          popupCategory: 'empty',
          productId: 'empty',
          weekOffer: '',
          productid: '',
          productPrice: '',
          productActionPrice: '',
          productOffer: '',
          productAction: '',
          actionPercent: '',
          freeShipping: '',
          productSlug: this.props.match.params.slug,
          categorySlug: this.props.match.params.category,
          totalPrice: '',
          totalPriceForFreeShipping: 0,
          loading:true,
          currentVariation: '',
          productVariations: [],
          addedProductVariationName: '',
          addedProductVariationType: '',
          priceExist: true,
          productVariationName: '',
          deliveryTime: '',
          available: '',
          productFilters: [],
          categoryName: '',
          subCategoryName: '',
          categorySlug: '',
          subCategorySlug: '',
          brandImage: '',
          brandMeta: '',
          brandLink: '',
          brandSlug: '',
          recension_comment: '',
          recension_email: '',
          recension_mark: '',
          recension_name: '',
          recension_date: '',
          recensionProductId: '',
          showPopupRecensionBOx: '',
          recensions: [],
          average_rating: 0,
          showThankYouRecension: false,
          nav1: null,
          nav2: null,
          currentMarkup: 1,
          categories: '',
          subcategories: '',
          productCategories: [],
          productSubategories: [],
          price: '',
          toggleHomeDeliveryClass: '',
          toggleFreePickupClass: '',
          prTitle: '',
          prPurpose: '',
          prKey: '',
        prSlug: '',
        prBrand: '',
        prPrice: '',
        prImage: '',
        prImage_thumb: '',
        prSliderImage1: '',
        prSliderImage2: '',
        prSliderImage3: '',
        prSliderImage4: '',
        prPriceAction: '',
        prCategory: '',
        prSubCategory: '',
        prProductOffer: '',
        prProductAction: '',
        prActionPercent: '',
        prDescription: '',
        prShortDescription: '',
        prShortDescriptionParsed: '',
        prSeoDescription: '',
        prDeliveryTime: '',
        prDeliverer: '',
        prProductVariety: '',
        prAvailable: '',
        prCategories: '',
        prSubCategories: '',
        bestSellers: [],
        brandDiscount: 0,
        };
    
        this.unsubscribe = null;
        this.togglePopupBox	= this.togglePopupBox.bind(this);
        this.hidePopupBox = this.hidePopupBox.bind(this);   
        this.productDataForPopup = this.productDataForPopup.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.toggleRecensionBox = this.toggleRecensionBox.bind(this);
        this.EnterHomeDelivery = this.EnterHomeDelivery.bind(this);
        this.LeaveHomeDelivery = this.LeaveHomeDelivery.bind(this);
        this.EnterFreePickup = this.EnterFreePickup.bind(this);
        this.LeaveFreePickup = this.LeaveFreePickup.bind(this);
      }

//Function: show popup box after adding new product in cart
togglePopupBox (arg) {
    if (this.state.showPopupClass === '') {
        this.setState({
            showPopupClass: arg
        })
     //   setTimeout(function(){
     //       this.setState({
     //           showPopupClass: ''
     //       })
     //  }.bind(this),7500);
    }
  }

  EnterHomeDelivery(){
    if (this.state.toggleHomeDeliveryClass === '') {
        this.setState({
            toggleHomeDeliveryClass: "active"
        })
    } 
  }

  LeaveHomeDelivery(){
    if (this.state.toggleHomeDeliveryClass === 'active') {
        this.setState({
            toggleHomeDeliveryClass: ""
        })
    } 
  }

  EnterFreePickup(){
    if (this.state.toggleFreePickupClass === '') {
        this.setState({
            toggleFreePickupClass: "active"
        })
    } 
  }

  LeaveFreePickup(){
    if (this.state.toggleFreePickupClass === 'active') {
        this.setState({
            toggleFreePickupClass: ""
        })
    } 
  }

//Function: hide popup box after adding new product in cart
hidePopupBox (arg){
    this.setState({
        showPopupClass: arg
    })
  }

//Function: pass product data to popup
productDataForPopup (title,slug, category, price,oldPrice,image,productId, quantity ){
    if(quantity === undefined) {
        quantity = 1;
    }
    this.setState({
        popupImage: image,
        popupPrice: price,
        popupOldPrice: oldPrice,
        popupSlug: slug,
        popupTitle: title,
        popupCategory: category,
        productId: productId,
        popupQuantity: quantity
    })
}

//Function: add slider images to array
sliderImage(image, slider1, slider2, slider3, slider4){
    sliderArr = [image, slider1, slider2, slider3, slider4];
    sliderArr = sliderArr.filter(value => Object.keys(value).length !== 0);
}

//Function: handle change of quantity for adding to cart
quantityHandler = (e) => {
    const { name, type, value } = e.target;
    const val = type === 'number' ? parseFloat(value) : value;  
    this.setState({
        productQuantity:val,
    });
}

//Function: calculate free shipping
getFreeShipping(){
var remaining = parseFloat(this.state.freeShipping) - parseFloat(this.state.totalPriceForFreeShipping)
    if(remaining > 0){
        return (
            <p>Još <span>{formatMoney(remaining)} </span> do besplatne dostave za narudžbe van grada Sarajeva.</p>
        )
    }
    else{
        return (
            <p>Čestitamo, ostvarili ste pravo na besplatnu dostavu.</p>
        )
    }
}

//Function: set product ID
productData(productid){
    this.setState({ productid: productid}); 
} 

//Function: redirect to cart after adding product(not used, previously used on buy now button)
redirectToCart(){
    setTimeout(function(){
        this.props.history.push("/"+cartUrl)
}.bind(this),100);
}

//Function: update category data when category is selected from header category menu
headerCategoryData(categorySlug){} 

//Function: update single product data when product is changed
singleProductData(productSlug){ //Fuction
    this.setState({ 
        productSlug: productSlug,
        loading: true,
    }); 
    this.getSingleProduct(productSlug);
    this.getRecensions();
}  

//Function: group variations based on variation type
groupVariationsBy(collection, property) {
    var i = 0, val, index;
  
       var values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        var variationOb = new Object();
        if (index > -1)
        {
            result[index].push(collection[i]);
        }
          
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}

//Function: handle changing price for variation products
handlePriceChange = (e) => {
    var variationType, variationName;
    if(e.target.value !== "")
    {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.state.productVariations.forEach(productVariation => {
            if(productVariation.id === parseInt(e.target.value)){
                this.setState({
                    priceExist: true,
                    productPrice: productVariation.price,
                    price: productVariation.price,
                    state,
                    addedProductVariationType: productVariation.type,
                    addedProductVariationName: productVariation.name,
                   })
                }        
        });
    }
    else{
        this.setState({
            priceExist:false,
        })
    }
}

//Function: handle recension form data
handleChangeRecension = (e) => {     
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    this.state.recension_date = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
}

//Function: show and hide recension box after adding new recensions
toggleRecensionBox () {
    if (this.state.showPopupRecensionBOx === '') {
        this.setState({
            showPopupRecensionBOx: 'show',
        })
    }
    else{
        this.setState({
            showPopupRecensionBOx: ''
        })
    }     
  }

//Function: submit recension
onSubmitRecension = (e) => {
    e.preventDefault();
    const {recensionProductId, recension_name,recension_email, recension_comment, recension_mark, recension_date } = this.state;
    console.log("sdgsdgsdgsdgd", recensionProductId)
    const newRecension = {
        productId: recensionProductId,
        recension_name,
        recension_email,
        recension_comment,
        recension_mark,
        recension_date,
        recension_approved: 1
        }
    addRecension(newRecension).then(res => {
    }).then((docRef) => {
    this.setState({
        showThankYouRecension: true,
        recension_name: '',
        recension_email: '',
        recension_comment: '',
        recension_mark: '',
    });
    setTimeout(function(){
        this.setState({
            showThankYouRecension: false,
            showPopupRecensionBOx: ''
        })
    }.bind(this),timeoutDelay);
    })
    .catch((error) => {
    this.setState({
        showStickerClass: 'show',
    })
    setTimeout(function(){
        this.setState({
            showStickerClass: ''
        })
    }.bind(this),timeoutDelay);
    this.setState({
    stickerMessage: 'Došlo je do problema prilikom dodavanja vaše recenzije: ' + error,
    stickerType: 'danger',
    })
}); 
    
}



//Function: add product to cart
addToCart(id, title, category,subCategory, slug, price, oldPrice, image_thumb, productAction, productOffer, deliverer, productVariety, brand, categorySlug, brandSlug, points) {
    var products = [];
    var newProduct;
    var totalPrice = 0;
    var totalPoints = 0;
    newProduct = new Object();
    newProduct.id = id;
    newProduct.title = title;
    newProduct.category = category;
    newProduct.subCategory = subCategory;
    newProduct.slug = slug;
    newProduct.price = price;
    newProduct.oldPrice = oldPrice;
    newProduct.quantity = this.state.productQuantity;
    newProduct.image = image_thumb;
    newProduct.productAction = productAction;
    newProduct.productOffer = productOffer;
    newProduct.deliverer = deliverer;
    newProduct.productVariety = productVariety;
    newProduct.brand = brand;
    newProduct.categorySlug = categorySlug;
    newProduct.brandSlug = brandSlug;
    newProduct.points = points;
    if(productVariety === 1){
        newProduct.productVariationName = this.state.addedProductVariationName;
        newProduct.productVariationType = this.state.addedProductVariationType;
    }
    this.props.setGlobalState({
        changeHeaderCart: true,
      })
    this.setState({
        CartPopupHidden: false
    })
        if(!localStorage.cartId){
            localStorage.cartId = cartId;
    
            products.push(newProduct ); 
            products = JSON.stringify(products); 
            this.setState({
                noVariationSelected: '',
                productVariationName: '',
            })  
            const newCart = {
                cartId,
                products,
                totalPrice: parseFloat(newProduct.price*newProduct.quantity),
                totalPoints: points*newProduct.quantity,
                }

            addCart(newCart).then(res => {
                }).then((response) => {
              })
              .catch((error) => {
                  console.log("problem ", error);
              });
        }
        else{
            console.log("vasa korpa je vec inicijalizirana");
            cartId = localStorage.cartId;
            axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/get-cart/' + cartId)
            .then((response) => {
            products = JSON.parse(response.data.products);
            totalPrice = response.data.totalPrice;
            totalPoints = response.data.totalPoints;
            for (var i in products) {
                if(products[i].id == newProduct.id)
                {
                    if(products[i].productVariationName == newProduct.productVariationName){
                        products[i].quantity = products[i].quantity+this.state.productQuantity;
                        products = JSON.stringify(products); 
                          this.setState({
                          noVariationSelected: '',
                          productVariationName: '',
                      }) 
                      axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                            cartId,
                            products,
                            totalPrice: parseFloat(totalPrice)+parseFloat(newProduct.price*newProduct.quantity),
                            totalPoints: totalPoints+(points*newProduct.quantity),
                          }).then((docRef) => {
                          })
                          .catch((error) => {
                              console.log("problem ", error);
                          });
                        return;
                    }
                  
                  
                }
            }  
            products.push(newProduct ); 
            products = JSON.stringify(products);  
            this.setState({
                noVariationSelected: '',
                productVariationName: '',
            }) 
            axios.post(process.env.REACT_APP_SERVER_PROXY_URL+'api/carts/update-cart/' + cartId, {
                cartId,
                products,
                totalPrice: parseFloat(totalPrice)+parseFloat(newProduct.price*newProduct.quantity),
                totalPoints: totalPoints + (points*newProduct.quantity),
              }).then((docRef) => {
                
              })
              .catch((error) => {
                  console.log("problem ", error);
              });
         
      });
    } 
                 
}

getRecensions(){
  
}

//Collect settings data
getSettings(){
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/settings/get-settings')
    .then((response) => {
      this.setState({
        weekOffer: response.data.weekOffer,
        freeShipping: response.data.freeShipping,
      });
      })
      .catch((error) => {
      console.log(error);
    })    
}

getSingleProduct(prSlug){
    this.setState({
        priceExist: true,
    })
    var newFiltersGroup, filtersCategoriesIds = [], filtersCategoriesFiltersIds = []; 
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/edit-product/' + prSlug)
    .then((response) => {
        const { product_id, purpose, title, slug, price, image,image_thumb, priceAction, description, shortDescription,brand, category, productOffer, 
            productAction, sliderImage1, sliderImage2, sliderImage3, sliderImage4, actionPercent,deliveryTime, seoDescription,
            deliverer, subCategory, productVariations, productVariety, available, vele_price, categories, subcategories } = response.data[0];

            this.setState({
                prKey: response.data[0]._id,
                prTitle: title,
                prPurpose: purpose,
                prSlug: slug,
                prBrand: brand,
                prPrice: price,
                prImage: image,
                prImage_thumb: image_thumb,
                prSliderImage1: sliderImage1,
                prSliderImage2: sliderImage2,
                prSliderImage3: sliderImage3,
                prSliderImage4: sliderImage4,
                prPriceAction: priceAction,
                prCategory: category,
                prSubCategory: subCategory,
                prProductOffer: productOffer,
                prProductAction: productAction,
                prActionPercent: actionPercent,
                prDescription: description,
                prShortDescription: shortDescription,
                prSeoDescription: seoDescription,
                prDeliveryTime: deliveryTime,
                prDeliverer: deliverer,
                prProductVariety: productVariety,
                prAvailable: available,
                prCategories: categories,
                prSubCategories: subcategories,
                brandImage: response.data[0].brandData[0].image,
                brandMeta: response.data[0].brandData[0].metaDesc,
                brandLink: response.data[0].brandData[0].link,
                brandDiscount: response.data[0].brandData[0].discount,
                brandSlug: response.data[0].brandData[0].slug,
                categoryName: response.data[0].categoryData[0].name,
                categorySlug: response.data[0].categoryData[0].slug,
                loading:false
            }) 

  //Collect recensions for single product
  var ratings = [], newRecension, recensions = [];
  axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/recensions/product/'+product_id)
  .then((response) => {
  response.data.forEach((doc) => {
      const { recension_name, recension_email, recension_comment,recension_approved, recension_mark, recension_date} = doc;
      ratings.push(parseInt(doc.recension_mark))  
      var average_sum_ratings = 0;
      for(var i in ratings){
          average_sum_ratings += ratings[i];
      }
      if(recension_approved === 1 && recension_comment !== ""){
          newRecension = new Object();
          newRecension.name = recension_name;
          newRecension.email = recension_email;
          newRecension.comment = recension_comment;
          newRecension.date = recension_date;
          newRecension.mark = recension_mark;
          recensions.push(newRecension);
      }
      this.setState({
          recensions: recensions,
          average_rating: average_sum_ratings/ratings.length,
      })   
  });
  });


axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/categories')
    .then((response) => {
        var productCategories = [];
        response.data.forEach((doc) => {
            var newCat = new Object(); 
        for(var i in categories){
            if(categories[i] === doc.category_id){
                newCat.name = doc.name;
                newCat.slug = doc.slug;
                    productCategories.push(newCat);
                }
            }

        })
            this.setState({
                productCategories,
        });
    })
    .catch((error) => {
        console.log(error);
})



    var filters = JSON.parse(response.data[0].filters);
    var ratings = [], newRecension, recensions = [];
    if(productVariety === 1){
        this.setState({
            priceExist:false,
            productVariations: JSON.parse(productVariations),
        })
    }
    this.setState({
        loading:false,
        recensionProductId: product_id,
        productPrice:vele_price,
        price: price,
        productActionPrice: priceAction,
        productOffer: productOffer,
        productAction: productAction,
        actionPercent: actionPercent,
        recensions: [],
        average_rating: 0,
    })


    //Collect filters for single product
    var productFilters = [], newFilter;
     filters.forEach(filter => {
        if(!filtersCategoriesIds.includes(filter.cat_id))
        filtersCategoriesIds.push(filter.cat_id);  
    })
    filters.forEach(filter => {
        filtersCategoriesFiltersIds.push(filter.filter_id);   
    })
    for(var i in filtersCategoriesIds) {
        axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/filters/product-filters/' + parseInt(filtersCategoriesIds[i]))
        .then((response) => {
        const { name,filter_id, filters  } = response.data;
        var usedFilters = [];
            newFiltersGroup = new Object();
            newFiltersGroup.groupName = name;
            newFiltersGroup.id = filter_id;
            JSON.parse(filters).forEach(filter => {
                for(var j in filtersCategoriesFiltersIds){
                    if(filtersCategoriesFiltersIds[j] === filter.id){
                        newFilter = new Object();
                        newFilter.value = filter.id;
                        newFilter.label = filter.name;
                        usedFilters.push(newFilter);
                    }
                }   
            })
            newFiltersGroup.filters = usedFilters;
            productFilters.push(newFiltersGroup);
            this.setState({
                productFilters: productFilters,
                })
            }); 
        }    
    })
    .catch((error) => {
    console.log(error);
  })  

}

bestSellersProducts(){
    var bestSellersAray = [];
    axios.get(process.env.REACT_APP_SERVER_PROXY_URL+'api/products/best-sellers')
    .then((response) => {
    response.data.forEach((doc) => {
        const { title, slug, price, image_thumb, priceAction, category, productOffer, productAction, actionPercent, 
            deliverer, productVariety, brand, vele_price, markup, purpose } = doc;
            bestSellersAray.push({
                key: doc._id,
                title,
                slug,
                price,
                image_thumb,
                priceAction,
                category,
                productOffer,
                actionPercent,
                productAction, 
                deliverer,
                productVariety,
                brand,
                markup,
                vele_price,
                purpose,
                productCategorySlug: doc.categoryData[0].slug,
                brandDiscount: doc.brandData[0].discount,
                productBrandName: doc.brandData[0].slug,
                productBrandFullName: doc.brandData[0].name,
                productBrandLogo: doc.brandData[0].image,
                }); 
            });
            this.setState({
                bestSellers: bestSellersAray,
            })
        })
        .catch((error) => {
        console.log(error);
    })  
}


componentDidUpdate(oldProps) {
    const newProps = this.props
    if(oldProps.match.params.slug !== newProps.match.params.slug) {
        this.setState({ 
            productSlug: newProps.match.params.slug,
        });
        this.componentDidMount();  
    }
}

componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({
        loading:false
    })
    this.setState({
        nav1: this.slider1,
        nav2: this.slider2
    });

      //Tawk chat API
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/5cb03c36d6e05b735b423133/default';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s1.setAttribute('rel','preconnect');
      s0.parentNode.insertBefore(s1,s0);
      })();
    
if(this.props.globalState.changeProductSlug === true){
    this.state.productSlug = this.props.globalState.singleProductSlug;
    this.getSingleProduct(this.state.productSlug);
    this.props.setGlobalState({
        changeProductSlug: false,
      })
}
    this.getSettings();
    this.getSingleProduct(this.state.productSlug);
    this.bestSellersProducts();
}

render(){
    var {productPrice, productOffer, weekOffer, productAction, actionPercent, productActionPrice, price, brandDiscount } = this.state;
    var productPricing = calculatePrice(productPrice, productOffer, weekOffer, productAction, actionPercent, productActionPrice, this.state.currentMarkup, price, brandDiscount);
    var points = productPricing.onePercentPrice;
    var pointsMoney = (productPricing.onePercentPrice)*10;
    var productVariations = this.groupVariationsBy(this.state.productVariations, "type");   
    //Remove single current product from bestSellers products
    for (var i = this.state.bestSellers.length - 1; i >= 0; i--)
    {
        if (this.state.bestSellers[i].slug === this.state.productSlug) {
            this.state.bestSellers.splice(i, 1);
            break;
        }
    }

    

//Const: bestSellers slider parameters
const bestSellersSliderParams = {
    dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnHover: true ,
        responsive: [
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 479,
              settings: {
                slidesToShow: 2,
              }
            },
          ]
};

   
var productSlug = this.state.productSlug;
const categorySlug = this.state.categorySlug;

  

const { productVariationName, deliveryTime, recension_name, recension_email, recension_comment, recension_mark, shortDescription,
    prTitle, prPurpose, prShortDescription, prImage, prSliderImage1, prSliderImage2, prSliderImage3, prSliderImage4, prProductVariety, prAvailable,
    prProductAction, prProductOffer, prKey, prCategory, prSubCategory, prSlug, prImage_thumb, prDeliverer, prBrand, prDescription, prSeoDescription} = this.state;
if(productSlug){

    console.log("product pricing", productPricing)
    console.log("product", prProductAction)
return(
    <div className={"shop " + categorySlug}>
  {/******************* Loader box *******************/}
  {this.state.loading && (<LoaderBox /> )}
    {/******************* Call Header component *******************/}
    <Header headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
    {!this.state.loading && (
    <>
    {/*<Popup />*/}
    {this.state.showPopupClass === 'show' && (
    <ProductPopup
    title={this.state.popupTitle}
    slug={this.state.popupSlug}
    category={this.state.popupCategory}
    price={this.state.popupPrice}
    oldPrice={this.state.popupOldPrice}
    image={this.state.popupImage}
    productId={this.state.productId}
    popupQuantity={this.state.popupQuantity}
    prAvailable={this.state.prAvailable}
    showClass={this.state.showPopupClass}
    hidePopupBox = {this.hidePopupBox.bind(this)} />
        )}

        {/******************* Single page intro *******************/}
        <>
        <img src={process.env.REACT_APP_FILES_DESTINATION+prImage} className="display-none"/>
        <section id="p_single_intro" className="section single-page-intro">
        <div className="container">
        <Helmet title ={prTitle +" › Zdravo.ba"}> </Helmet>
       {/* <Helmet
            title ={prTitle +" › Zdravo.ba"}
            description={prSeoDescription}
            meta={[
            {property: 'og:title', content: prTitle +" › Zdravo.ba" },
            {property: 'og:description', content: prSeoDescription },
            {property: 'og:image', content: process.env.REACT_APP_FILES_DESTINATION+prImage },
            {property: 'og:image:type', content: "image/jpg" },
            {property: 'og:type', content: "website" },
            {property: 'og:url', content: "https://www.zdravo.ba/proizvod/"+this.state.categorySlug + "/"+ this.state.brandSlug+"/"+this.state.prSlug},
            {property: 'twitter:card', content: process.env.REACT_APP_FILES_DESTINATION+prImage },
            {property: 'twitter:image:alt', content: prTitle + " › Zdravo.ba" },
            ]} >
        </Helmet>*/}
        {/******************* Product popup *******************/}
            <div className="columns">
             
                {/******************* Product title for mobile *******************/}
           
                  <h1 className="title is-mobile">{prTitle}</h1>
                  <h4 className="purpose is-mobile">{prPurpose}</h4>
                  <div className="brand-rating is-mobile">
                        {((this.state.brandImage !== "" && this.state.brandImage !== "null" && this.state.brandImage !== "empty") && this.state.brandSlug !== "ostalo") && (
                        <Link to={"/brend/"+this.state.brandSlug}><img src={process.env.REACT_APP_FILES_DESTINATION+this.state.brandImage} className="brand-image" alt={this.state.brandMeta} title={this.state.brandMeta}/></Link>
                        )}
                        <div className="rating">
                        <div className="stars">
                        <AnchorLink offset='80' href='#description'className="product-marks">Ocjena proizvoda</AnchorLink>
                        <StarRatings
                        rating={this.state.average_rating}
                        starRatedColor="#00E9D4"
                        numberOfStars={5}
                        name='rating'
                        starDimension="17px"
                        starSpacing="1px"
                        />
                        </div>
                    
                        <span className="comments-button" onClick={this.toggleRecensionBox.bind(this)}><span className="icon"></span>Ocijenite proizvod</span>
                        </div>
                    </div>
                {/******************* Product gallery images *******************/}
                <div className="column is-5 slider-image">
                    <div className="columns inner">
                    <div>{this.sliderImage(prImage,prSliderImage1, prSliderImage2, prSliderImage3, prSliderImage4)}</div>
                        {/******************* Product thumbnails *******************/}
                        <SingleProductSlider 
                        image={prImage}
                        sliderArr={sliderArr}  
                        flagAction={productPricing.flagAction}
                        percent_discount={productPricing.percent_discount} 
                        save_money={productPricing.save_money}
                        prPurpose={prPurpose}
                        />

                    </div>
                </div>
                
                {/******************* Product main content(title, stars, comments button, short description, variation box, categories, availability) *******************/}
                <div className="column is-4 content">
                    <h1 className="title is-desktop">{prTitle}</h1>
                    <h4 className="purpose is-desktop">{prPurpose}</h4>
                    <div className="brand-rating is-desktop">
                        {((this.state.brandImage !== "" && this.state.brandImage !== "null" && this.state.brandImage !== "empty") && this.state.brandSlug !== "ostalo") && (
                        <Link to={"/brend/"+this.state.brandSlug}><img src={process.env.REACT_APP_FILES_DESTINATION+this.state.brandImage} className="brand-image" alt={this.state.brandMeta} title={this.state.brandMeta}/></Link>
                        )}
                        <div className="rating">
                        <div className="stars">
                        <AnchorLink offset='80' href='#description'className="product-marks">Ocjena proizvoda</AnchorLink>
                        <StarRatings
                        rating={this.state.average_rating}
                        starRatedColor="#00E9D4"
                        numberOfStars={5}
                        name='rating'
                        starDimension="17px"
                        starSpacing="1px"
                        />
                        </div>
                    
                        <span className="comments-button" onClick={this.toggleRecensionBox.bind(this)}><span className="icon"></span>Ocijenite proizvod</span>
                        </div>
                    </div>
                   
                   {/*  <div className="points-box is-desktop">
                    
                   {points === 0 &&(
                    <p>Ovaj proizvod ne donosi kupon bodove.</p>
                   )} 
                   {points > 0 && (
                       <>
                        <i className="icon"></i>
                    <p>Kupovinom ovog proizvoda dobijate <span>{points}</span> 
                     {(points >=5 || points === 0) && ( <span> bodova </span> )}
                     {points < 5 && points > 1 && ( <span> boda </span> )}
                     {points === 1 && ( <span> bod </span> )}
                     vjernosti koje možete pretvoriti u kupon vrijednosti <span>{formatMoney(pointsMoney)}</span>.</p>
                     </>
                   )}
                 
                    </div> */}
                    
                    {ReactHtmlParser(prShortDescription)}
                    <div className="button-area">
                            <AnchorLink offset='80' href='#description' className="button read-more">Više o proizvodu</AnchorLink>
                    </div>
                    <div className="price-box is-mobile">
                        {this.state.priceExist && (
                            <div class="price-box">
                            <span>Cijena:</span>
                            <h4 className="price"> {formatMoney(productPricing.price)}</h4>
                            {productPricing.flagAction && productPricing.percent_discount > 0 && (
                                <h6 className="old-price"> {formatMoney(productPricing.oldPrice)}</h6>
                            )}
                            {productPricing.brand && productPricing.percent_discount === "0" && (
                                <h6 className="old-price"> {formatMoney(productPricing.oldPrice)}</h6>
                            )}
                            </div>
                        )}
                        {!this.state.priceExist && (
                            <div className="price-box">
                                <span><b>Odaberite jednu od ponuđenih opcija kako bi dobili cijenu proizvoda.</b></span>
                            </div>
                        )}
                        {prAvailable === 1 && (
                        <p className="product-stock is-mobile yes">Proizvod je odmah isporučiv.</p>
                    )}
                        {prAvailable === 0 && (
                            <p className="product-stock is-mobile no">Proizvod nije odmah isporučiv.</p>
                        )}
                    </div>
                    {!this.state.priceExist && (
                        <p className="no-variation-selected">{this.state.noVariationSelected}</p>
                    )}
                    {prProductVariety === 1 && (
                    <div className="variations-box">
                    <p>Odaberite opciju</p>
                    { productVariations.map(productVariation => (
                     <div className="single">
                        {/*
                        productVariation.slice(0, 1).map(innerPV => (           
                        <p className="variation-title">{innerPV.type}</p>
                        ))
                        */}
                       <select id="productVariationName" name="productVariationName" value={productVariationName} onChange={this.handlePriceChange}>
                       {
                        productVariation.slice(0, 1).map(innerPV => (
                            <option value="">{innerPV.type}</option>           
                        ))
                        }
                       
                           {productVariation.map(innerPV => (    
                                <option value={innerPV.id}>{innerPV.name}</option>
                            ))}
                       </select>
  
                   </div>
                        ))}
                    </div>
                    )}

                    <p className="category-button"><span className="icon"></span>Kategorije<br />
                    <ul>
                        {this.state.productCategories.map(productCategory => (
                            <li className="category-single">
                            <Link to={`/${categoryUrl}/${productCategory.slug}`} className=""> {productCategory.name}</Link>
                            </li>
                            ))}
                        {/*   <Link to={`/${categoryUrl}/${this.state.categorySlug}/${this.state.subCategorySlug}`}>{this.state.subCategoryName}</Link> */}
                    </ul>
                    </p>

                  
                    {prAvailable === 1 && (
                        <p className="product-stock is-desktop yes">Proizvod je odmah isporučiv.</p>
                    )}
                    {prAvailable === 0 && (
                        <p className="product-stock is-desktop no">Proizvod nije odmah isporučiv.</p>
                    )}

                  
                    
                </div>

                {/******************* Product Pricing box(price, action price, free shipping info, variation info, quantity, add to cart button) *******************/}
                <div className="column is-3 pricing">
                <div className="is-desktop">
                    {this.state.priceExist && (
                            <div class="price-box">
                            <span>Cijena:</span>
                            <h4 className="price"> {formatMoney(productPricing.price)}</h4>
                            {productPricing.flagAction && productPricing.percent_discount > 0 && (
                                <h6 className="old-price"> {formatMoney(productPricing.oldPrice)}</h6>
                            )}
                            {productPricing.brand && productPricing.percent_discount === "0" && (
                                <h6 className="old-price"> {formatMoney(productPricing.oldPrice)}</h6>
                            )}
                            </div>
                    )}
                    {!this.state.priceExist && (
                        <div className="price-box">
                            <span><b>Odaberite jednu od ponuđenih opcija kako bi dobili cijenu proizvoda.</b></span>
                        </div>
                    )}
                </div>
                
                  

                   {/* <div className="free-shipping">
                        {this.getFreeShipping()}
                    </div>
                 */}
                  
                    <div className="shipping-box">
                    <h4 className="shipping-method">Načini dostave</h4>
                    <div className="columns is-multiline inner">
                        <div className="column is-12 home-delivery" onMouseLeave={this.LeaveHomeDelivery.bind(this)}>
                            <p onMouseOver={this.EnterHomeDelivery.bind(this)}><i></i>Kućna dostava <span className="info"></span></p>
                            <div className={"home-delivery-table shipping-table " + this.state.toggleHomeDeliveryClass}>
                                <div className="columns titles">
                                    <p className="column is-6">Vrsta narudžbe</p>
                                    <p className="column is-3">Cijena dostave</p>
                                    <p className="column is-3">Minimalna narudžba</p>
                                </div>
                                <div className="columns results">
                                    <p className="column is-6 is-block"><b>GRATIS PICK-UP</b></p>
                                    <p className="column is-3">GRATIS</p>
                                    <p className="column is-3">25KM</p>
                                </div>
                                <div className="columns results">
                                    <p className="column is-6">KUĆNA DOSTAVA 7KM</p>
                                    <p className="column is-3">7KM</p>
                                    <p className="column is-3">0KM</p>
                                </div>
                                <div className="columns results">
                                    <p className="column is-6">KUĆNA DOSTAVA 5KM</p>
                                    <p className="column is-3">5KM</p>
                                    <p className="column is-3">40KM</p>
                                </div>
                                {/* __CHANGE */}
                                {/* <div className="columns results">
                                    <p className="column is-6">KUĆNA DOSTAVA</p>
                                    <p className="column is-3">5KM</p>
                                    <p className="column is-3">40KM</p>
                                </div> */}
                                <div className="columns results">
                                    <p className="column is-6"><b>GRATIS KUĆNA DOSTAVA</b></p>
                                    <p className="column is-3">GRATIS</p>
                                    <p className="column is-3">100KM</p>
                                </div>
                               {/* <div className="columns results">
                                    <p className="column is-12">Pri finalnom koraku kupovine ćete imati opciju odabira 
                                    <br />- mjesta i <br />- vrste dostave</p>
                                 </div>*/}
                                <div className="columns results">
                                    <p className="column is-12 is-block">Sve narudžbe možete obaviti i putem telefona 
                                    <b> 061 93 93 48</b> ili email-a <a href="mailto:narudzba@zdravo.ba"><b>narudzba@zdravo.ba</b></a> </p>
                                </div>
                            </div>
                        </div>
                        <div className="column is-12 free-pickup"  onMouseLeave={this.LeaveFreePickup.bind(this)}>
                            <p onMouseOver={this.EnterFreePickup.bind(this)}><i></i>Gratis PICK-UP<span className="info"></span></p>
                            <div className={"free-pickup-table shipping-table " + this.state.toggleFreePickupClass}>
                                <div className="columns titles">
                                    <p className="column is-12 is-block"><b>Iskoristite mogućnost besplatnog preuzimanja</b> već u 
                                    vrijednosti narudžbe od 25 KM:</p>
                                </div>
                                <div className="columns results list-places is-multiline">
                                    <p className="column is-12"><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</p>
                                    <p className="column is-12"><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</p>
                                    <p className="column is-12"><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</p>
                                </div>
                               {/* <div className="columns results">
                                    <p className="column is-12">Pri finalnom koraku kupovine ćete imati opciju odabira 
                                    <br />- mjesta i <br />- vrste dostave</p>
                                </div>*/}
                                <div className="columns results">
                                    <p className="column is-12 is-block">Sve narudžbe možete obaviti i putem telefona 
                                    <b> 033 500-818</b> ili email-a <a href="mailto:narudzba@zdravo.ba"><b>narudzba@zdravo.ba</b></a> </p>
                                </div>
                            </div>
                        </div>

                      
                    </div>
                    </div>
                            
                    <div className="quantity-box">
                    <p>Količina</p>
                    <input type="number" className="quantity" min="1" defaultValue="1" onChange={this.quantityHandler}/>
                    </div>

                    <button className="button add-to-cart"
                        onClick={() => {
                            {this.state.priceExist && 
                            this.addToCart(prKey, prTitle, prCategory,prSubCategory, prSlug, productPricing.price, productPricing.oldPrice, prImage_thumb,
                            prProductAction, prProductOffer, prDeliverer, prProductVariety,prBrand,this.state.categorySlug, this.state.brandSlug,points,
                            this.togglePopupBox('show'), 
                            this.productDataForPopup(prTitle, prSlug, prCategory, productPricing.price, productPricing.oldPrice,prImage_thumb,prKey, 
                                this.state.productQuantity)
                            )} 
                            {!this.state.priceExist && this.setState({noVariationSelected: "Molimo da odaberete jednu od ponuđenih opcija"})}}
                            }>
                        <span></span>Dodaj u korpu</button>
                    <button className="button buy-now"
                    onClick={() => {
                        {this.state.priceExist && 
                        this.addToCart(prKey, prTitle,prCategory,prSubCategory, prSlug, productPricing.price, productPricing.oldPrice, 
                            prImage_thumb,prProductAction, prProductOffer, prDeliverer, prProductVariety, prBrand.brand, this.state.categorySlug, 
                            this.state.brandsSlug, points,
                        this.togglePopupBox(), this.redirectToCart())}
                        {!this.state.priceExist && this.setState({noVariationSelected: "Molimo da odaberete jednu od ponuđenih opcija"})}}
                        }
                        >    
                    <span></span>Kupi odmah</button>
                   {/* <Link to={"/"+cartUrl} className="button buy-now"><span></span>Kupi odmah</Link> */}

                   <div className="points-box is-mobile">
                    <i className="icon"></i><p>Kupovinom ovog proizvoda dobijate <span>{points}</span> 
                     {(points >=5 || points === 0) && ( <span> bodova </span> )}
                     {points < 5 && points > 1 && ( <span> boda </span> )}
                     {points === 1 && ( <span> bod </span> )}
                     vjernosti koje možete pretvoriti u kupon vrijednosti <span>{formatMoney(pointsMoney)}</span>.</p>
                    </div>
                </div>
            </div>
    </div>{/* CONTAINER END */}
    
    </section>{/* SINGLE PAGE SECTION END */}

    {/******************* Product Description *******************/}
    <section id="description" className="section single-page-desc">
        <div className="container">
        <Tabs>
            <div className="header-menu">
                <TabList>
                    <Tab><span className="product-desc">Opis proizvoda</span></Tab>
                    <Tab><span className="product-attributes">Svojstva proizvoda</span></Tab>
                    <Tab><span className="product-shipping">Dostava</span></Tab>
                    <Tab><span className="product-pricing-types">Način plaćanja</span></Tab>
                    <Tab><span className="product-recensions">Recenzije</span></Tab>
                </TabList>
            </div>
            <TabPanel id="description1" className="content product-desc">
                <div className="inner">
                    {ReactHtmlParser(prDescription)}
                </div>
            </TabPanel>
            <TabPanel className="content product-attributes">
                <div className="inner">
                    {this.state.productFilters.map(filterGroup => ( 
                            <div className="column is-12">
                            <h6 className="attributes-group-name">{filterGroup.groupName}</h6>
                            <div className="attributes-box">
                                {filterGroup.filters.map(filter => (
                                    <p className="single-attribute">{filter.label}</p>
                                ))}  
                            </div>  
                            </div>          
                    ))}
                </div>
            </TabPanel>
            <TabPanel className="content product-shipping">
                <div className="inner">
                    <h3>Mogućnosti preuzimanja narudžbe i cijene isporuke</h3>

                    <h4>1. Kućna dostava</h4>
                    <ul>
                        <li>Odabirom opcije plaćanja pouzećem, odabrali ste automatski i dostavu narudžbe na Vašu kućnu adresu. Troškove dostave pogledajte u tabeli ispod:</li>
                        <img src={placanje_pouzecem_img} />
                        <li>Pošiljku šaljemo u roku od 24h nakon izvršene narudžbe.</li>
                    </ul>
                    <h4>2. Gratis pick-up</h4>
                    <ul>
                        <li><b>Želite izbjeći troškove dostave? Preuzmite lično Vašu narudžbu na jednom od  GRATIS PICK-UP mjesta!</b></li>
                        <li>Pošiljku možete preuzeti sa odabranog PICK-UP mjesta već u roku od 24 h nakon izvršene narudžbe. 
                            <b> Bit ćete obavješteni telefonskim putem</b> o prispjeću narudžbe na PICK-UP mjesto.</li>
                        <li>Iznos minimalne narudžbe: 25 KM</li>
                        <p>PICK-UP mjesta se nalaze na sljedećim lokacijama:</p>
                        <ul>
                            <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                            <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                            <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                        </ul>
                    </ul>
                </div>

            </TabPanel>
            <TabPanel className="content product-pricing-types">
                <div className="inner">
                <h3>Vrste plaćanja</h3>
                  {/*  <h4 className="paypal-title">1. Plaćanje putem PayPal-a <span className="icon"></span></h4>
                    <ul>
                        <li>Izaberite željene proizvode</li>
                        <li>Izaberite PayPal kao opciju plaćanja</li>
                        <li>Za uplate prispjele do 10:00 sati roba će biti poslana isti dan.</li>
                    </ul>
                                */}
                    <h4>1. Plaćanje pouzećem na kućnoj adresi </h4>
                    <ul>
                        <li>Izaberite i naručite željene proizvode </li>
                        <li>Izaberite opciju plaćanja pouzećem </li>
                        <li>Dostavljač će na Vašu adresu doći s pošiljkom i prilikom isporuke zatražiti da mu predate iznos vrijednosti naručene robe.</li>
                        <li>Narudžbe izvršene do 10:00 sati će biti poslane isti dan </li>
                    </ul>

                    <h4>2. Plaćanje pouzećem na GRATIS PICK-UP mjestu</h4>
                    <br />
                    <p><b>Želite izbjeći troškove dostave? Preuzmite lično Vašu narudžbu na jednom od  GRATIS PICK-UP mjesta!</b></p>
                    <ul>
                        <li>Izaberite i naručite željene proizvode </li>
                        <li>Izaberite sa liste Vašem boravištu najbliže PICK-UP mjesto </li>
                        <li>Na PICK-UP mjestu preuzmite narudžbu i platite samo cijenu proizvoda</li>
                        <li>Narudžbe izvršene do 10:00 sati će biti spremne za preuzimanje u roku od 24h nakon izvršene narudžbe. 
                            <b>Biti ćete obavješteni telefonskim putem</b> o prispjeću narudžbe na PICK-UP mjesto.</li>
                    </ul>
                    <p><b>PICK-UP mjesta se nalaze na sljedećim lokacijama:</b></p>
                    <ul>
                        <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                        <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                        <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                    </ul>
                </div>
            </TabPanel>
            <TabPanel id="recensions" className="content product-recensions">
                <div className="inner">
                    <div className="button-area">
                        <span className="button default" onClick={this.toggleRecensionBox.bind(this)}>Ostavite vašu recenziju</span>
                    </div>

                    <div className="recensions-box">
                        {this.state.recensions.map(recension => (
                            <div className="single-recension">
                                <span className="avatar"></span>
                                <div className="content">
                                    <h6 className="name">{recension.name}</h6>
                                    <div className="meta">
                                    {recension.email !== "" && (
                                        <span className="email">{recension.email}</span>
                                    )}     
                                    <span className="date">{recension.date}</span>
                                    </div>
                                    <p className="comment">{recension.comment}</p>
                                    
                                </div>
                                <div className="rate">
                                <StarRatings
                                rating={parseInt(recension.mark)}
                                starRatedColor="#C8E351"
                                numberOfStars={5}
                                name='rating'
                                starDimension="15px"
                                starSpacing="1px"
                                />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                 
            </TabPanel>
        </Tabs>
        </div>
    </section>

    {/******************* bestSellers products slider *******************/}
    <section id="p_single_recomended" className="section single-page-recomended">
            <div className="container">
                <div className="title-box-main">
                    <h4 className="title" >NAJČEŠĆE PRODAVANI PROIZVODI</h4>
                </div>{/* TITLE BOX MAIN END */} 
                    <div className="products-slider margin-0">
                    
                <Slider {...bestSellersSliderParams} >
                    {console.log(this.state.bestSellers)}
                {this.state.bestSellers.map (bestSeller => (
                    <div className="is-3 single">

                    {/******************* Call product component *******************/}
                    <Product product={bestSeller} 
                    key={bestSeller.key} 
                    togglePopupBox = {this.togglePopupBox.bind(this)} 
                    productDataForPopup={this.productDataForPopup.bind(this)}
                    singleProductData={(productSlug) => this.singleProductData(productSlug)}
                    />
                    </div>
                    ))}
                </Slider> 
                    </div>
                    

            </div>{/*CONTAINER END */}
    </section>{/* SECTION SINGLE RECOMENDED END */}
    </>


            {/******************* Recension form box *******************/}
            <div className={"popup-wrapper recensions-popup " + this.state.showPopupRecensionBOx}>
            <div className="overlay-back"></div>
                <form name="add-comment" onSubmit={this.onSubmitRecension} className="form default-form popup-box popup-delete">
                <span className="close" onClick={this.toggleRecensionBox.bind(this)}></span>
                    {!this.state.showThankYouRecension && (
                    <fieldset>
                        <h1 className="part-title">Ostavite recenziju</h1>
                        <div className="columns is-multiline form">
                            <label htmlFor="recension_name" className="column is-6">
                                <input type="text" id="recension_name" name="recension_name" placeholder="Ime*"
                                value={recension_name} onChange={ this.handleChangeRecension } required />
                            </label>
                            <label htmlFor="recension_email" className="column is-6">
                                <input type="email" id="recension_email" name="recension_email" placeholder="E-mail adresa"
                                value={recension_email} onChange={ this.handleChangeRecension }/>
                            </label>
                            <label htmlFor="recension_comment" className="column is-12">
                                <textarea id="recension_comment" name="recension_comment" placeholder="Komentarišite"
                                value={recension_comment} onChange={ this.handleChangeRecension }></textarea>
                            </label>
                            <label htmlFor="recension_mark" className="column is-6">
                                <select id="recension_mark" name="recension_mark" value={recension_mark} onChange={ this.handleChangeRecension }>
                                <option value="0">Vaša ocjena za proizvod</option>
                                <option value="1">Ne valja (1)</option>
                                <option value="2">Zadovoljavajuće (2)</option>
                                <option value="3">Solidan (3)</option>
                                <option value="4">Vrlo dobar (4)</option>
                                <option value="5">Odličan (5)</option>
                                </select>
                            </label>
                        </div>
                        <div className="button-area">
                            <button type="submit" className="button default"><span className="icon"></span>OSTAVITE RECENZIJU</button>
                            {/*  <PaypalExpressBtn client={client} currency={'USD'} total={1.00} /> */}
                        </div>
                    </fieldset>)}
                    {this.state.showThankYouRecension && (
                        <div className="thank-you">
                            <span className="icon"></span>
                            <p>Hvala vam. Vaša recenzija nam je od velikog značaja.</p>
                        </div>
                    )}
                </form>   
            </div> 

    {/******************* Call footer component *******************/}
        <Footer headerCategoryData={(categorySlug) => this.headerCategoryData(categorySlug)}/>
    </> )}
    </div>
)
}
else{
return(
    <p>Proizvod ne postoji.</p>
);
}
    }
}

export default withGlobalState(SingleProduct)