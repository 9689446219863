/*
FAQ page
Display questions and answers from customers
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import { homeUrl, registerUrl, termsOfUseUrl, privacyPolicyUrl, contactUrl } from '../../../functions/pageUrls';
import { Link } from 'react-router-dom'
import narudzba_img from '../../../static/placanje_pouzecem_img.png'

var pageTitle = "Najčešća pitanja i odgovori";
class FAQ extends React.Component{

    componentDidMount() {
        }
        
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
                <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
              </Helmet>
                <section id="p_faq" className="section static-page faq-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <div className="faq-box">
                                <p className="question">Osim preko online trgovine, mogu li naručiti proizvode putem e-mail-a ili telefona?</p>
                                <p className="answer">Naravno. Imate 3 mogućnosti narudžbe:</p>
                                <ul className="answer">
                                    <li> <p className="answer">Putem naše online trgovine <Link to={homeUrl}>www.zdravo.ba</Link></p>
                                    <p className="margin-bottom-small answer">- U online trgovini se možete registrovati kao korisnik pri čemu Vaši podaci ostaju memorisani i olakšavaju Vam svaku novu narudžbu,</p>
                                    <p className="margin-bottom-small answer">- ili kupovinu obaviti kao gost, pri kojoj Vaši lični podaci neće biti spašeni u našoj bazi podataka, te ćete svaki puta iznova morati 
                                        unositi Vaše podatke potrebne za dostavljanje narudžbe.</p>
                                    </li>
                                    <li><p className="margin-bottom-small answer">Putem email-a <a href="mailto:narudzba@zdravo.ba">narudzba@zdravo.ba</a> pri čemu svaki puta iznova morati unositi Vaše podatke potrebne za dostavljanje narudžbe.</p></li>
                                    <li><p className="answer">Putem telefona <a href="tel:061 93 93 48">061 93 93 48</a>, Facebook-a ili Instagram-a, pri čemu ćete svaki puta iznova morati ostavljati Vaše podatke 
                                        potrebne za dostavljanje narudžbe.</p></li>
                                </ul>
                            </div>

                            <div className="faq-box">
                                <p className="question">Mogu li naručene proizvode osobno podignuti i time izbjeći troškove dostave?</p>
                                <p className="answer">Da, proizvode možete podignuti osobno na našim GRATIS PICK-UP lokacijama:</p>
                                <ul className="answer">
                                    <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                                    <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                                    <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                                </ul>
                                <p className="answer">Pošiljku možete preuzeti sa odabranog PICK-UP mjesta već u roku od 24 h nakon izvršene narudžbe. <b>Bit 
                                ćete obavješteni telefonskim putem o prispjeću narudžbe na PICK-UP mjesto.</b></p>
                                <p className="answer"><b>Iznos minimalne narudžbe za GRATIS PICK-UP: 25 KM</b></p>
                            </div>

                            <div className="faq-box">
                                <p className="question">Kako se mogu registrovati na online trgovini ZDRAVO.BA?</p>  
                                <p className="answer">Registrovati se možete na tri načina:</p>
                                <ul className="answer">
                                    <li><p className="answer">Klikom na link PRIJAVA u gornjem desnom uglu stranice pri čemu će Vam se pružiti mogućnost da kliknete na 
                                    <Link to={registerUrl}> KREIRAJTE RAČUN</Link>. U formular ćete upisati Vaše lične podatke, korisničko ime i lozinku koje ćete koristiti za svaku novu narudžbu.</p></li>
                                    <li><p className="answer">Prijava je moguća i u toku obavljanja kupovine kao gost. Nakon što u formular koji Vam se pojavi putem narudžbe 
                                    upišete Vaše lične podatke, u dnu formulara će Vam se ponuditi mogućnost korištenja unešenih podataka u cilju registracije kao korisnika.</p></li>
                                </ul>
                            </div>

                            <div className="faq-box">
                                <p className="question">Zašto moram dati broj telefona?</p>
                                <p className="answer">Broj telefona nam je potreban radi lakše komunikacije prilikom dostave. Vaš broj telefona prosljeđujemo našoj kurirskoj 
                                dostavnoj službi.</p>
                            </div>

                            <div className="faq-box">
                            <p className="question">Postoji li minimalan iznos narudžbe, koliko iznosi poštarina?</p>
                            <p className="answer">Da. Pogledajte ovdje detalje:</p>
                            <p className="answer"><b>1.	Mogućnosti isporuke i minimalni iznos narudžbe</b></p>
                            <img src={narudzba_img} />
                            <p className="answer">Pošiljku šaljemo u roku od 24h nakon izvršene narudžbe</p>
                            <p className="answer"><b>2.	Gratis pick-up</b></p>
                            <p className="answer">Želite izbjeći troškove dostave? Preuzmite lično Vašu narudžbu na jednom od GRATIS PICK-UP mjesta!</p>
                            <p className="answer">Pošiljku možete preuzeti sa odabranog PICK-UP mjesta već u roku od 24 h nakon izvršene narudžbe. 
                            <b>Bit ćete obavješteni telefonskim putem o prispjeću narudžbe na PICK-UP mjesto.</b></p>
                            <p className="answer">Iznos minimalne narudžbe: 25 KM</p><br />
                            <p className="answer">PICK-UP mjesta se nalaze na sljedećim lokacijama:</p>
                            <ul className="answer">
                                <li><b>“Apoteka Valor”</b> Rogačići broj 1, Sarajevo, Ilidža 71215, radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</li>
                                <li><b>1. "WINK SPORT"</b> D.O.O.-Prodavnica br. 17.- adresa: Koševo 3, Opština Centar, Sarajevo, tel: 033/216-204, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00</li>
                                <li><b>2. "WINK SPORT"</b> D.O.O.-Prodavnica br. 7.- adresa: Trg međunarodnog prijateljstva 1.Opština Novi grad, Sarajevo,  tel: 033/465-622, Radno vrijeme: PON-PETAK 08.00-17.00, SUBOTA 08.00-15.00 (Alipašino polje)</li>
                            </ul>
                            </div>

                            <div className="faq-box">
                            <p className="question">Šta ako neke proizvode koje želim naručiti nisu trenutno raspoloživi ili ih nemate u asortimanu?</p>
                            <p className="answer">Na našoj online trgovini ćete uz svaki proizvod naći informacije o dostupnosti tog proizvoda. Ukoliko nismo u mogućnosti isporučiti pošiljku u 
                            navedenom roku, obavijestit ćemo Vas pravovremeno. Vi pri tom možete odlučiti želite li obustaviti narudžbu ili pričekati da naručeni proizvod 
                            bude dostupan.</p>
                            <p className="answer">Ukoliko želite naručiti neki proizvod koji se ne nalazi u našem asortimanu pišite nam jednostavno na email <a href="mailto:kontakt@zdravo.ba">kontakt@zdravo.ba</a> ili putem 
                            <Link to={contactUrl}> KONTAKTNOG FORMULARA</Link> a mi ćemo se potruditi da Vam željeni proizvod u najkraćem roku stavimo na rapolaganje.</p>
                            </div>

                            <div className="faq-box">
                            <p className="question">Mogu li naručene proizvode platiti platnom karticom?</p>
                            <p className="answer">Ne. Svaka transakcija karticama je povezana sa dodatnim troškovima. Obzirom da ZDRAVO.BA radi sa minimalnim cijenama 
                            ovaj način plaćanja bi povećao cijene proizvoda u našoj online trgovini te ga iz tog razloga nismo integrisali. Trenutno imate mogućnost 
                            jednostavnog plaćanja pouzećem, prilikom dostavljanja proizvoda na Vašu kućnu adresu.   </p>
                            </div>

                            <div className="faq-box">
                            <p className="question">Šaljete li naručene proizvode izvan granica Bosne i Hercegovine?</p>
                            <p className="answer">Isporuku naručenih proizvoda vršimo i izvan granica BiH. U obzir se uzimaju tarife dostave koje se razlikuju od 
                            zemlje do zemlje. Ovakve narudžbe preuzimamo isključivo putem email-a, a plaćanje je moguće samo kreditnom karticom.</p>
                            <p className="answer">S obzirom da se radi o dostavi u druge države, zbog carinskih postupaka, ne možemo utjecati na vrijeme dostave. 
                            Ograničenje za težinu paketa koji smo u mogućnosti poslati van BiH (zajedno s ambalažom) je 2 kilograma.  </p>
                            </div>

                            <div className="faq-box">
                            <p className="question">Kakva je cijena dostave za inostranstvo?</p>
                            <p className="answer">Cijena dostave za područja izvan BiH se utvrđuje prema specifičnim tarifama za svaku zemlju. Sve narudžbe za inostranstvo 
                            se obavljaju isključivo putem email-a <a href="mailto:narudzba@zdravo.ba">narudzba@zdravo.ba</a> .</p>
                            </div>

                            <div className="faq-box">
                            <p className="question">Ko snosi odgovornost u slučaju kašnjenja isporuke?</p>
                            <p className="answer">Isporuku proizvoda vršimo preko kompanija koje obavljaju poslove dostave pošiljaka u BiH. U poslovanju ovih kompanija 
                            propisani su uslovi i rokovi isporuke pošiljaka na predviđene lokacije, 
                            te u slučaju kašnjenja isporuke servis dostave snosi određene konsekvence propisane njihovim pravilima poslovanja. </p>
                            </div>

                            <div className="faq-box">
                                <p className="question">Koliko dugo traje isporuka naručenih artikala?</p>
                                <p className="answer">Narudžbe izvržene prije podne se šalju isti ili naredni dan (osim nedelje). Firme kojima predajemo proizvode ih u 
                                pravilu dostavljaju u Roku od 1-2 radna dana na Vašu kućnu adresu.</p>
                            </div>

                            <div className="faq-box">
                                <p className="question">Gdje mogu napisati svoje mišljenje i utiske o vašem web shop servisu?</p>
                                <p className="answer">Vaše mišljenje nam je važno. Vaše komentare, mišljenja, pitanja, pohvale, kritike i sugestije možete poslati 
                                putem e-maila na našu adresu <a href="mailto:kontakt@zdravo.ba">kontakt@zdravo.ba</a> ili putem  <Link to={contactUrl}>kontaktnog formulara</Link> na web shop-u. </p>
                                <p className="answer">Sve komentare po pitanju proizvoda koje ste naručivali u web shop-u <Link to={homeUrl}>www.zdravo.ba</Link> možete ostaviti u 
                                polju za komentare pored proizvoda koji ste naručivali.</p>
                                <p className="answer">Vaše komentare, prijedloge, pohvale, kritike možete ostaviti također i na našoj FB stranici.</p>
                            </div>

                            <div className="faq-box">
                                <p className="question">Mogu li vratiti naručene proizvode?</p> 
                                <p className="answer">Naručene proizvode, koji su i dalje u originalnom fabričkom ispravnom stanju, možete vratiti u Roku od 14 dana od 
                                momenta dostave. Pri tom jedino snosite troškove dostave. Ukoliko želite reklamirati proizvod ili nešto drugo vezano za naručeni 
                                proizvod pogledajte prethodno informacije na stranici  <Link to={termsOfUseUrl}>USLOVI KORIŠTENJA</Link> te nam se obratite putem email-a <a href="mailto:kontakt@zdravo.ba">kontakt@zdravo.ba</a> ili 
                                preko <Link to={contactUrl}> KONTAKTNOG FORMULARA</Link> na web shop-u.</p>
                                <p className="answer">Vraćeni proizvodi moraju biti u dobrom stanju, neotvoreni, nekorišteni i u originalnoj ambalaži. Nakon što smi 
                                primili vraćenu robu, izvršit će se povrat plaćenog istim sredstvima plaćanja kojim ste platili robu. </p>
                            </div>

                            <div className="faq-box">
                                <p className="question">Koliko je sigurno naručiti putem vaše stranice?</p> 
                                <p className="answer">Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> je u vlasništvu kompanije PA "VALOR", koja Vam garantuje profesionalnost te Vas na 
                                stranici <Link to={privacyPolicyUrl}> ZAŠTITA LIČNIH PODATAKA</Link> informiše o Vašoj sigurnosti kao korisnika online trgovine <Link to={homeUrl}>www.zdravo.ba</Link></p> 
                            </div>

                            <div className="faq-box">
                                <p className="question">Da li nam garantujete za sigurnost deponovanih ličnih podataka za registraciju na vašem web shopu?</p>
                                <p className="answer">Vlasnik online trgovine, firma PA "VALOR" se obavezuje na čuvanje privatnosti podataka svih korisnika web shopa <Link to={homeUrl}>www.zdravo.ba</Link>. Pogledajte više na ZAŠTITA PODATAKA.</p>
                            </div>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default FAQ