/*
Terms of use page
Display terms of use of shop
*/
import React from 'react'
import ShopWrapper from '../ShopWrapper'
import MainTitle from '../../helpers/MainTitle'
import {Helmet} from "react-helmet";
import {siteTitle} from '../../../functions/config'
import { Link } from 'react-router-dom'
import { homeUrl } from '../../../functions/pageUrls';
var pageTitle = "Uslovi korištenja";
class TermsOfUse extends React.Component{

    componentDidMount() {
        }
        
    render(){
        window.scrollTo(0,0); 
        return(
            <ShopWrapper>
               <Helmet>
                    <title>{pageTitle} &rsaquo; {siteTitle}</title>
               </Helmet>
                <section id="p_terms_of_use" className="section static-page terms-of-use-page privacy-policy-page">
                    <div className="container">
                        {/******************* Main title *******************/}
                        <MainTitle title={pageTitle} />

                        {/******************* Content *******************/}
                        <div className="content">
                            <h4>Uvod</h4>
                            <p>Molimo Vas da pažljivo pročitate Uslove korištenja odnosno pravilnik o korištenju naše online trgovine <Link to={homeUrl}>www.zdravo.ba</Link>, 
                                koji regulišu proces naručivanja, plaćanja i dostave prodajnih artikala iz naše online trgovine.</p>
                            <p>Samim pregledavanjem i korištenjem sadržaja, te kupovinom na online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> se podrazumijeva da svjesno
                                 prihvatate njihov sadržaj, kao i sve njegove naknadne izmjene, bez prethodne najave i obavijesti.</p> 
                            <p>Narudžbe u online trgovini <Link to={homeUrl}>www.zdravo.ba</Link> mogu praviti samo punoljetne osobe.</p>
                            <p>Korisnici naše online trgovine su dužni usluge online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> koristiti na savjestan način putem 
                                kojeg niko neće povrijeđen ili materijalno oštećen. </p>
                            <p>Za sve ponuđene proizvode na stranicama postoji objavljena slika i osnovni opis proizvoda. </p>
                            <h4>Kontakt</h4>
                            <p>Sarajevo – “Apoteka Valor” Rogačići broj 1, Sarajevo, Ilidža 71215</p>
                            <p>Telefon / Viber / WhatsApp: <a href="tel:+38733500818">+38733500818</a></p>
                            <p>Radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</p>
                            <p>E-Mail: <a href="mailto:info@zdravo.ba">info@zdravo.ba</a> </p>
                            <h4>Informacije o proizvodima:</h4>
                            <p>Informacije o proizvodima ponuđenim na <Link to={homeUrl}>www.zdravo.ba</Link> su preuzete s deklaracija proizvoda,  unutarnjih uputa proizvoda ili 
                                su preuzete iz materijala (brošura, letaka, web stranica) koje je uredio proizvođač, uvoznik ili dobavljač pojedinog proizvoda. 
                                Fotografije su preuzete od proizvođača odnosno dobavljača ili su lično urađene. Obzirom na obim proizvoda u online trgovini, 
                                kao i na povremene izmjene dizajna ili vrste pakovanja od strane proizvođača, fotografije ne moraju nužno biti identične kao 
                                trenutni originalan proizvod. </p>
                            <p>Nastojimo obezbijediti što tačnije i detaljnije informacije o proizvodima naše online trgovine, ali ne isključujemo mogućnost greške 
                                prilikom unošenja opisa proizvoda. 
                                Prije kupovine proizvoda, naročito ako se radi o dermokozmetici, medicinskim proizvodima i dodacima prehrani, preporučujemo prethodno 
                                savjetovanje sa Vašim ljekarem. </p>
                            <p>Stavovi korisnika izraženi u recenzijama proizvoda ne moraju se podudarati sa stvarnim osobinama proizvoda kao ni sa mišljenjima našeg 
                                tima ili ljekara. Radi se o slobodnim izjavama na koje ne možemo znatno uticati.</p>
                            <p>Prema važećem zakonu, u BiH se putem interneta ne smiju prodavati lijekovi.</p>
                            <h4>Odricanje od odgovornosti:</h4>
                            <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> nije zamjena za liječnički pregled niti konsultacije sa Vašim liječnikom. Informacije koje nađete na 
                            <Link to={homeUrl}>www.zdravo.ba</Link> ne smiju služiti za uspostavljanje dijagnoze. Ukoliko posumnjate na određene simptome dogovorite pregled kod Vašeg 
                                ljekara. Ako imate bilo kakve nedoumice oko izbora preparata za samoliječenje, opravdanosti ili načina korištenja nekih preparata 
                                najbolje se posavjetujte s Vašim ljekarem.</p>
                            <p>Ukoliko ste trudni ili dojite ili koristite neke lijekove, prije izbora preparata za samoliječenje savjetujte se s ljekarem.</p>
                            <p>Bitno je znati da prodavač nije odgovoran za stavove na drugim web stranicama koje su linkovane sa <Link to={homeUrl}>www.zdravo.ba</Link> niti se na njih ne 
                                odnose ovi Uvjeti korištenja.</p>
                            <p>Korištenjem online trgovine <Link to={homeUrl}>www.zdravo.ba</Link> smatra se da ste saglasni da <Link to={homeUrl}>www.zdravo.ba</Link> neće biti odgovorna ni za kakvu izravnu ili 
                                neizravnu, materijalnu ili nematerijalnu štetu nastalu interpretiranjem informacija ili odabirom preparata sa online trgovine 
                                <Link to={homeUrl}>www.zdravo.ba</Link>.</p>
                            <h4>Kupovina</h4>
                            <p>Sve cijene su izražene u KM-ovima, a PDV je uključen u cijenu svakog proizvoda.
                            Proizvodi se naručuju odabirom, korištenjem menija “košarica” i popunjavanjem elektronskog formulara. U slučaju da naručenog artikla 
                            trenutno nema na skladištu, bićete kontaktirani u najkraćem mogućem roku te ćemo u dogovoru s Vama isporučiti zamjenski proizvod a 
                            naručeni proizvod ukloniti iz narudžbe. Vaša trenutna narudžba prikazana je na stranici “Moj račun”. Na toj stranici prikazani su 
                            detaljni podaci o artiklu, količini i cijeni. Svi artikli koje ste odlučili kupiti nalaze se na stranici pod imenom ”košarica”.</p>
                            <h4>Način plaćanja</h4>
                            <p>Plaćanje možete izvršiti pouzećem što podrazumjeva da ćete pošiljku platiti dostavljaču prilikom preuzimanja. Dostavljač će na Vašu adresu 
                                doći s pošiljkom i prilikom isporuke zatražiti da mu predate iznos za kojeg je pošiljatelj tražio da se naplati pouzećem. Primaocima 
                                pošiljaka ne zaračunavaju se nikakvi dodatni troškovi. Plaćanje je moguće isključivo gotovinom.</p>
                            <h4>Isporuka</h4>
                            <p className="margin-bottom-small"><b>Kućna dostava</b></p>
                           <p>Proizvode naručene radnim danima do 10.00 možemo poslati u roku od 24h.</p> 
                           <p className="margin-bottom-small">- Dostava je besplatna širom BiH za narudžbe veće od 100 KM.</p>
                           <p  className="margin-bottom-small">- Dostava za narudžbe od 40 – 100 KM se plaća 5 KM.</p>
                           <p  className="margin-bottom-small">- Dostava za narudžbe od 0 – 40 KM se plaća 7 KM.</p>
                            <p>- Cijena dostave za područja izvan BiH se utvrđuje prema specifičnim tarifama za svaku zemlju. Sve narudžbe za inostranstvo se obavljaju isključivo 
                                putem email-a narudzba@zdravo.ba .</p>
                            <p><Link to={homeUrl}>www.zdravo.ba</Link> ne preuzima odgovornost u slučaju kašnjenja isporuke zbog netačne ili nepotpune adrese, više sile ili problema u saobraćaju.</p> 
                            <p>Ukoliko ne budemo u mogućnosti poslati narudžbu zbog nedostupnosti proizvoda koji ste naručili, obavijestiti ćemo Vas o tome. Vi tada možete 
                                odlučiti da li ćete obustaviti narudžbu ili pričekati dostupnost proizvoda.</p>
                            <p>Naručene proizvode dostavljamo ili putem sopstvenih kurira ili putem pošte. Ukoliko Vas naš kurir ili poštanski službenik ne nađe na adresi 
                                dostave, ostavit će vam obavijest o prispijeću pošiljke. Na obavijesti će po mogućnosti biti naznačeno u kojem poštanskom uredu ili na kojoj 
                                adresi I u kojem vremenskom roku možete preuzeti paketsku pošiljku. Prilikom preuzimanja pošiljke trebate imati ispravu za ličnu 
                                identifikaciju (lična karta, pasoš i sl…)</p>
                            <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> ne može uticati na eventualna oštećenja koja mogu nastati tijekom dostave putem pošte, eventualnih 
                                kašnjenja ili ne isporuke pošiljke nakon predaje paketa pošti.</p>
                            <p>Ako potrošač (kupac) odbije primiti robu koju je naručio, online trgovina zdravo.ba ima pravo tražiti od potrošača nadoknadu svih 
                                troškova vezanih za isporuku.</p>
            
                            <p className="margin-bottom-small"><b>Gratis PICK-UP</b></p>
                            <p>Ukoliko želite izbjeći troškove dostave pošiljku možete preuzeti i sa odabranog GRATIS PICK-UP mjesta već u roku od 24 h nakon izvršene narudžbe. 
                                Bit ćete obavješteni telefonskim putem o prispjeću narudžbe na PICK-UP mjesto. Iznos minimalne narudžbe za GRATIS PICK-UP: 25 KM</p>
                            <p>PICK-UP mjesta se nalaze na sljedećim lokacijama:</p>
                            <p className="margin-bottom-small"><b>Sarajevo</b> – Sarajevo – “Apoteka Valor” Rogačići broj 1, Sarajevo, Ilidža 71215</p>
                            <p className="margin-bottom-small">Radno vrijeme Pon - Sri; 08.00h - 18.00h, Cet; 10.00h - 20.00h, Pet; 08.00 - 18.00h, Sub; 08.00h - 14.00h</p>
                            <p><Link to={homeUrl}>www.zdravo.ba</Link> ne preuzima odgovornost u slučaju kašnjenja isporuke zbog netačne ili nepotpune adrese, više sile ili problema u saobraćaju.</p>
                        <h4>Odgovornost za materijalne nedostatke</h4>
                        <p>U skladu sa  odredbama zakona odgovaramo za materijalne nedostatke proizvoda ukoliko su nedostaci postojali u trenutku prijelaza rizika na kupca 
                            kao i  za one materijalne nedostatke koji se pojave nakon prijelaza rizika na kupca ako su posljedica uzroka koji je postojao prije toga. 
                            O postojanju materijalnog nedostatka na kupljenom proizvodu dužni ste nas odmah obavijestiti u najkraćem roku. Ne odgovaramo za nedostatke 
                            proizvoda koji se pojave na proizvodu nakon isteka vremena garantovanog za održanje ispravnosti proizvoda.</p>
                        <p>Molimo Vas da prilikom preuzimanja provjerite stanje pošiljke te u slučaju vidnih oštećenja odmah reklamirate proizvod našem kuriru ili poštankom službeniku, 
                            te da ih ne preuzimate ako su proizvodi oštećeni ili pokazuju neke druge manjkavosti. U suprotnom reklamacije i prigovori neće biti uvaženi.</p>
                        <h4>Reklamacije i prigovori</h4>
                        <p>Kupac robe preko online trgovine <Link to={homeUrl}>www.zdravo.ba</Link>, ima pravo, ne navodeći razloge za to, jednostrano raskinuti sklopljeni ugovor u roku od 14 dana, 
                            od datuma isporuke proizvoda.</p>
                        <p>Kupac je dužan prije isteka roka za jednostrani raskid ugovora obavijestiti <Link to={homeUrl}>www.zdravo.ba</Link> o svojoj odluci da raskine ugovor i to pismene izjave kojom izražava 
                            svoju volju da raskine ugovor, na e-mail:<a href="mailto:info@zdravo.ba">info@zdravo.ba</a>   i/ili na adresu: “Apoteka Valor” Rogačići broj 1, Sarajevo, Ilidža 71215. U slučaju raskida ugovora
                             svaka je strana dužna vratiti drugoj ono što je primila u istoj vrijednosti i količini.</p>
                        <p>Iskoristi li kupac pravo na jednostrani raskid ugovora, mora izvršiti povrat robe bez odgađanja, a najkasnije u roku od 14 dana. Primljeni artikl 
                            potrošač mora vratiti neoštećen i u nepromijenjenoj količini. Troškove dostave vraćenih proizvoda snosi kupac, osim u slučaju da se radi o 
                            grešci koju je na bilo koji način prilikom kupoprodaje načinila online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> .</p>
                        <p>Kupac je odgovoran za svako umanjenje vrijednosti vraćene robe koja je proizašla kao rezultat rukovanja robom, te online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> 
                            u tom slučaju neće vratiti plaćena sredstva ili prihvatiti povrat oštećenog artikla. Kupac mora snositi samo izravne troškove povrata robe, 
                            osim ako je online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> pristala snositi sve troškove.</p>
                        <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> mora izvršiti povrat plaćene kupoprodajne cijene najkasnije u roku od 14 dana nakon što mu neoštećena i/ili neupotrjebljena 
                            roba bude vraćena, služeći se istim sredstvima kojima se služio potrošač prilikom plaćanja osim ako potrošač izričito ne pristane na neko drugo sredstvo 
                            plaćanja, te uz pretpostavku da prodavatelj ne mora platiti nikakve dodatne troškove za takav povrat.</p>
                        <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> snosi troškove povrata robe jedino ako su dostavljeni krivi ili oštećeni artikli nastali pogreškom uposlenika online 
                        trgovine <Link to={homeUrl}>www.zdravo.ba</Link>. 
                            Molimo da se prilikom svakog preuzimanja pošiljke pregledaju artikli, ukoliko su oštećeni ili umanjene vrijednosti da se ne preuzimaju od dostavljača (kurira). 
                            U suprotnom sve reklamacije i prigovori neće biti uvaženi. </p> 
                        <h4>Garancija i servisni uvjeti: </h4> 
                        <p>Ukoliko određeni proizvod ima garanciju ili podliježe servisnim uvjetima, to će biti naglašeno u opisu tog proizvoda. Kupac je dužan čuvati garantni list i račun za vrijeme 
                            trajanja roka garancije. Samo s predočenjem računa i garantnog lista kupac može ostvariti svoja prava.</p>
                        <h4>Informacije o proizvodima</h4>
                        <p>Informacije o proizvodima ponuđenim na web stranici su preuzete sa deklaracija pojedinih proizvoda, unutrašnjih  uputstava proizvoda ili su preuzete 
                            iz materijala (brošura, letaka, web stranica) koje je uredio proizvođač, uvoznik ili dobavljač pojedinog proizvoda. Nastojimo pružiti što tačnije 
                            i detaljnije informacije o pojedinom proizvodu. Zadržavamo pravo greške u opisu ili fotografiji pojedinog proizvoda.</p>
                        <p>Nakon kupovine bilo kog proizvoda sa ove internet stranice, preporučujemo da uvijek dobro pročitate uputstvo proizvođača i da se prilikom primjene 
                            ili upotrebe rukovodite istim.	</p>
                        <h4>Ostali uslovi</h4>
                        <p>Uslovi načina prodaje se mogu promijeniti bez prethodne najave. Sve potvrđene narudžbe ćemo isporučiti po uslovima koji važe u momentu potvrđivanja narudžbe. 
                        PA "VALOR" zadržava pravo ne isporučiti robu ukoliko narudžba ne sadrži potpune ili netačne podatke naručioca.</p>
                        <h4>Kako postati korisnik</h4>
                        <p>Da biste postali naš kupac potrebno je obaviti prijavu kao novi korisnik. Postupak prijave vrlo je jednostavan i zahtjeva minimum Vaših ličnih podataka.Vaši 
                            podaci potrebni su nam kako bi PA "VALOR" nesmetano i sigurno sproveo postupak naručivanja i dostavu proizvoda na Vašu adresu. Prijavu možete uraditi i 
                            u toku kupovine kao gost. Nakon ispunjenog formulara jednostavno kliknite u prozorčić da pristajete da se Vaši uneseni podaci iskoriste u cilju registracije.</p>
                        <br />
                        <h4>Zaštita prava autora PA "VALOR"</h4>
                        <p>Podaci na <Link to={homeUrl}>www.zdravo.ba</Link> su zaštićeni Zakonom o autorskom pravu i srodnim pravima u BiH.</p>
                        <p>Dizajn online trgovine, logo kao i svi ostali sadržaji online trgovine su proizvod napora i rada tima PA "VALOR" te je shodno prethodno spomenutom zakonu o autorskim 
                            pravima svako kopiranje ili bespravno korištenje sadržaja nedozvoljeno osim uz prethodnu saglasnost ovlaštenih osoba ispred PA "VALOR".</p>
                        <h4>Nadležnost suda i mjerodavno pravo.</h4>
                        <p>Online trgovina <Link to={homeUrl}>www.zdravo.ba</Link> cilj je sve sporove rješavati mirnim putem. Na sve neriješene sporove koji mogu nastati prilikom kupoprodajnih djelatnosti, 
                            primjenjuje se pravo Republike Bosne i Hercegovine sa nadležnim sudom u Sarajevu.</p>
                        <h4>Odricanje od odgovornosti</h4>
                        <p>Sa najboljom savješću i ogromnim trudom smo se potrudili sve proizvode svrstati u kategorije prema njihovim osobinama, kako bismo Vam omogućili što lakšu i što efikasniju 
                            pretragu. Ne isključujemo mogućnost da smo kod nekih proizvoda napravili greške pri obilježavanju. Iz tog razloga obaveza svakog kupca je da i sam pregleda specifikacije 
                            proizvoda, naročito ako se radi o proizvodima koji ne bi trebali sadržavati alergene i tvari na koje organizam reaguje sa intolerancijama.</p>
                        <p>Naša online trgovina ZDRAVO.BA je servis izmedju Vas i dostavljača robe. Nismo u mogućnosti raditi kvalitativne analize proizvoda koje Vam dostavljamo niti istraživati da 
                            li svi proizvodi zadovoljavaju pravne norme, te po ovom pitanju ne preuzimamo odgovornost.</p>
                        </div>
                    </div>{/* CONTAINER END */}
                </section>{/*ABOUT US SECTION END */}
            </ShopWrapper>
        )
    }
}

export default TermsOfUse